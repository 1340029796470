var Handlebars = require("/www/www.mmartel.opinionsystem.successmarket.fr/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "			<a  href=\"/newsarticle/"
    + alias2(alias1((depth0 != null ? depth0.news_article_id : depth0), depth0))
    + "\" class=\"mdc-card news-card\">\r\n				<div class=\"article-mouseover\"></div>\r\n				<div class=\"mdc-card__primary-action\">\r\n					<div class=\"mdc-card__media mdc-card__media--16-9 news-card__img-wrapper\" style=\"background-image: url('"
    + alias2(alias1((depth0 != null ? depth0.featured_image : depth0), depth0))
    + "');\">\r\n						<span class=\"news-card__date-circle\">"
    + alias2(alias1((depth0 != null ? depth0.published_date : depth0), depth0))
    + "</span>\r\n					</div>\r\n					<div class=\"news-card__article-content\">\r\n						<div class=\"mdc-card__primary news-card__article-headline\">\r\n							<p>"
    + alias2(alias1((depth0 != null ? depth0.title : depth0), depth0))
    + "</p>\r\n						</div>\r\n						<div class=\"mdc-card__secondary news-card__article-description\">\r\n							<p>"
    + alias2(alias1((depth0 != null ? depth0.intro_paragraph : depth0), depth0))
    + " </p>\r\n						</div>\r\n					</div>\r\n				</div>\r\n			</a>\r\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"mdc-layout-grid container\">\r\n	<div class=\"mdc-layout-grid__inner\">\r\n		<div class=\"mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop os-align-cell-center\">\r\n			<h2>"
    + ((stack1 = __default(require("/www/www.mmartel.opinionsystem.successmarket.fr/module/handlebars-helpers/i18n_t.js")).call(alias1,"index:news_articles.header",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":4,"column":7},"end":{"line":4,"column":48}}})) != null ? stack1 : "")
    + "</h2>\r\n			<p class=\"os-typography__h2-leading-text\">"
    + ((stack1 = __default(require("/www/www.mmartel.opinionsystem.successmarket.fr/module/handlebars-helpers/i18n_t.js")).call(alias1,"index:news_articles.sub_header",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":5,"column":45},"end":{"line":5,"column":90}}})) != null ? stack1 : "")
    + "</p>\r\n		</div>\r\n	</div>\r\n</div>\r\n<div class=\"home-news-slider\">\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.news_article : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":1},"end":{"line":28,"column":10}}})) != null ? stack1 : "")
    + "</div>\r\n<div class=\"mdc-layout-grid container\">\r\n	<div class=\"mdc-layout-grid__inner\">\r\n		<div class=\"mdc-layout-grid__cell mdc-layout-grid__cell--span-4-phone mdc-layout-grid__cell--span-8-tablet mdc-layout-grid__cell--span-12-desktop os-align-cell-center\">\r\n			<a class=\"mdc-button mdc-button--outlined os-button--primary-inverted-outlined view-more-news\" href=\"/news\" target=\"_blank\" >"
    + ((stack1 = __default(require("/www/www.mmartel.opinionsystem.successmarket.fr/module/handlebars-helpers/i18n_t.js")).call(alias1,"index:news_articles.anchor_text",{"name":"i18n_t","hash":{},"data":data,"loc":{"start":{"line":33,"column":128},"end":{"line":33,"column":174}}})) != null ? stack1 : "")
    + "</a>\r\n		</div>\r\n	</div>\r\n</div>";
},"useData":true});